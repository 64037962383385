<template>
  <section class="hero">
    <iframe
      :style="{
        height: 'fit-content',
        maxHeight: '100vh',
        height: 'calc(100vh - 219px)',
      }"
      :src="cpxURL"
    />
  </section>
</template>

<script>
export default {
  asyncComputed: {
    async cpxURL() {
      return (await axios.get('/cpx/me')).data.url
    }
  }
};
</script>

<style>
</style>